#deleteFlowModal_Overlay{
    width: 100vw;
    height: 0vh;  
    position: fixed;
    z-index: 900;
    display: flex;
    justify-content: center;
    transition: background-color 0.7s, height 0s;
    background-color: rgba(0, 0, 0,0);
    backdrop-filter: blur(8px);
}

#deleteFlowModal{
    top: 0;
    overflow: hidden;
    position: fixed;
    width: 44vw;
    height: 0vw;
    border-radius: 15px;
    margin-top: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color1);
    transition: 0.2s;
}

#deleteFlowModal{
    font-weight: bold;
}

#deleteFlowModal p{
    font-size: large;
}

#deleteFlowModal_CloseIcon_overlay{
    display: flex;
    flex-direction: row-reverse;
    width: 97%;
    margin-bottom: 1VW;
}

#deleteFlowModal_CloseIcon_overlay img{
    margin-top: 0.7VW;
    margin-right: 0.5VW;
    width: 1.5VW;
    height: 1.5VW;
}

#deleteFlowModal_btnOverlay{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-content: space-between;
    align-items: center;
    width: 41.2%;
}

#deleteFlowModal_btnOverlay button{
    margin-top: 3vw;
    width: 8vw;
    height: 2.5vw;
    font-size: 0.88em; 
    font-weight: bold;
    background-color: var(--color5);
    color: var(--color1);
}

#deleteFlowModal_btnOverlay button:hover{
    color: var(--color5);
    background-color: var(--color1);
}








@media screen and (max-width: 601px){
    #mobile_DeleteFlowModal_Overlay{
        width: 100vw;
        height: 0vh;  
        position: fixed;
        z-index: 900;
        display: flex;
        justify-content: center;
        transition: background-color 0.7s, height 0s;
        background-color: rgba(0, 0, 0,0);
        backdrop-filter: blur(0px);
    }
    
    #mobile_DeleteFlowModal{
        top: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        position: fixed;
        width: 70vw;
        height: 0vw;
        border-radius: 15px;
        margin-top: 15vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color1);
        transition: 0.2s;
    }
    
    #mobile_DeleteFlowModal{
        font-weight: bold;
    }

    #mobile_DeleteFlowModal_CloseIcon_overlay{
        display: flex;
        flex-direction: row-reverse;
        width: 97%;
        height: 5VW;
        margin-bottom: 5VW;
    }
    
    #mobile_DeleteFlowModal_CloseIcon_overlay img{
        margin-top: 1.5VW;
        width: 5VW;
        height: 5VW;
    }
    
    #mobile_DeleteFlowModal_btnOverlay{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        justify-content: space-between;
        align-items: center;
        width: 68%;
    }
    
    #mobile_DeleteFlowModal_btnOverlay button{
        margin-top: 18vw;
        width: 20vw;
        height: 7vw;
        font-size: 0.88em; 
        font-weight: bold;
        background-color: var(--color5);
        color: var(--color1);
        /* margin-bottom: 4vw; */
    }
    
    #mobile_DeleteFlowModal_btnOverlay button:hover{
        color: var(--color5);
        background-color: var(--color1);
    }
}

