#selectAppsModal{
    width: 100vw;
    height: 0vh;  
    position: absolute;
    z-index: 900;
    display: flex;
    justify-content: center;
    transition: background-color 0.8s, height 0s;
    background-color: rgba(0, 0, 0,0);
}

#myApps{
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    width: 70vw;
    height: 0vw;
    border-radius: 25px;
    margin-top: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--color1);
    transition: 0.3s;
}

#myApps .myApps_Overlay{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.movileAppNavIcon{
    width: 11vw;
    height: 11vw;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
}


.mobileNav_selected{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
    margin-bottom: 0.5vw;
    height: 18vw;
    width: 18vw;
}