.nav_blocks{
  width: 2.3vw;
  height: 2.3vw;
  /* background-color: aqua; */
}
#user_account_overlay{
  position: relative;
  display: inline-block;
  direction: rtl;
  min-width: 12vw;
}
#user_accounts{
  display: flex;
  flex-direction: row;
	justify-content: space-between;
	align-items: center;
  direction: ltr;
}

#user_accounts div:first-child{
  display: flex;
  flex-direction: row;
	align-items: center;
}

.account_logo{
  background-color: var(--color1);
  border-radius: 50%;
  margin-right: 0.3vw;
  /* background-image: url(../../../icons/closeSquare_active.png);
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center center;
  background-size: auto;

  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

#dropdown_icon{
  margin-left: 2vw;
  width: 1.3vw;
  height: 1.3vw;
  background-image: url(../../../icons/arrow-down.png);
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center center;
  background-size: auto;

  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
}

#accounts_overlay{
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 501;
  overflow-x: hidden;
  overflow-y: unset;
  width: 0;
  height: 0;
  transition: 0.15s;
  /* display: flex;
  flex-direction: column; */
  direction: ltr;
}

#accounts_overlay::-webkit-scrollbar{
  width: 0.6vw;
}

#accounts_overlay::-webkit-scrollbar-track{
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(223, 217, 217);
}

#accounts_overlay::-webkit-scrollbar-thumb{
  background-color: var(--color8);
  border-radius: 10px;
  border: 0.2vw solid rgb(223, 217, 217);
}

#accounts_overlay::-webkit-scrollbar-corner{
  background-color: darkgray;
}

.dropdown_account_logo{
  background-color: var(--color1);
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  border: 1px solid var(--color7);
  margin-right: 0.5vw;
}

#user_account_overlay:hover #accounts_overlay {
  width: 100%;
  height: 7vw;
  min-height: 2.5vw;
}

#accounts_overlay label {
  width: 100%;
}

#accounts_overlay button {
  color: var(--color2);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#accounts_overlay button:hover {
  color: var(--color1);
}

#accounts_overlay button div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#accounts_overlay button input {
  margin-right: 0.2vw;
}

#user_account_overlay form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
