#userVerificationOverlay{
    display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center; 
    width: 71.8%;
    border-left: 3px solid var(--color7); 
    border-right: 3px solid var(--color7);
    height: calc(100vh - 8vw);
    overflow-y: scroll;
}

#SuccessCongratulationStatus{
    display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center; 
    /* border: 2px solid red; */
}

#SuccessCongratulationStatus h2{
    font-size: 2.3vw;
    font-weight: bold;
    color: var(--color5);
}

#SuccessCongratulationStatus p{
    margin-top: 1vw;
    font-size: 1.1vw;
    font-weight: bold;
}

#SuccessCongratulationStatus div{
    margin-bottom: 1vw;
    width: 22vw;
    height: 20vw;
    /* margin-right: 0.3vw; */

    background-image: url(../../../icons/welcome.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#SuccessCongratulationStatus button{
    margin-top: 3vw;
    font-size: 1vw;
    font-weight: bold;
    width: 10vw;
    color: var(--color1);
    background-color: var(--color5);
}

#SuccessCongratulationStatus button:hover{
    color: var(--color5);
    background-color: var(--color1);
}

@media screen and (max-width: 601px){
    #userVerificationOverlay{
        width: 100%;
        border-left: 0px solid var(--color7); 
        border-right: 0px solid var(--color7);
        height: calc(100vh - 8vw);
        /* overflow-y: scroll; */
    }
    
    #SuccessCongratulationStatus h2{
        font-size: 1.7em;
        color: var(--color5);
    }
    
    #SuccessCongratulationStatus p{
        margin-top: 1vw;
        font-size: 0.8em;
        font-weight: bold;
    }
    
    #SuccessCongratulationStatus div{
        margin-bottom: 1vw;
        width: 52vw;
        height: 50vw;
        /* margin-right: 0.3vw; */
    
        background-image: url(../../../icons/welcome.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;
    
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    
    #SuccessCongratulationStatus button{
        margin-top: 10vw;
        font-size: 0.8em;
        width: 30vw;
        color: var(--color1);
        background-color: var(--color5);
    }
}