h3{
    color: var(--color1);
}
#ratioOverlay{
    background-color: var(--color1);
    border-left: 3px solid var(--color7); 
    border-right: 3px solid var(--color7); 
    width: 100%;
    height: calc(100vh - 12.35vw);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
}

#ratioOverlay::-webkit-scrollbar{
    height: 1vw;
    /* width: 0.8vw; */
    width: 0.4vw;
}

#ratioOverlay::-webkit-scrollbar-thumb{
    background-color: var(--color8);
    border-radius: 10px;
}

#ratioOverlay::-webkit-scrollbar-corner{
    background-color: darkgray;
}

/* ..................................................................................................... */
#ratioHomeform{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.5vw;
    background-color: var(--color1);
    direction: ltr;
}

#ratioHomeform .reverseDiv{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 1vw;
}

#ratioHomeform .reverseDiv div{
    width: 61.2%; 
    display: flex;   
    align-items: center;
}

#ratioHomeform .reverseDiv div input{
    padding-left: 0.4vw;
    padding-right: 0.4vw; 
}

#ratioHomeform .reverseDiv div select{
    width: 5vw;
    height: 2.15vw;
    border-radius: 7px;
    font-size: 0.7vw;
    background-color: white;
    border: 2px solid var(--color7);
}

#ratioHomeform .reverseDiv div option{
    font-size: 0.8vw;
    
}

#ratioHomeform .reverseDiv p{
    margin-right: 0.3vw;
}

#filterDateFrom{
    width: 11vw;
    height: 2vw;
    margin-right: 1.9vw;
}

#filterDateTo{
    width: 11vw;
    height: 2vw;
    margin-right: 1.9vw;
}

#all_ratios_overlay{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    width: 57.1vw;
    direction: ltr;
}

.each_Ratio_BigOverlay{
    width: 57.1vw;
    height: 14vw;
    border-bottom-left-radius: 21px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    color: var(--color1);
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.each_Ratio_BigOverlay div:first-child{
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.each_Ratio_BigOverlay div:first-child h3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

.each_Ratio_BigOverlay div:first-child  h3 .h3_normal{
    font-size: 1.6vw;
    margin-top: 2vw;
}

.each_Ratio_BigOverlay div:first-child h3 .h3_percentage{
    font-size: 5vw;
    /* font-size: min(max(4vw, 2.5vw), 2.5vw); */
    /* font-size: clamp(16px, 50%, 22px); */
    font-size: clamp(1rem, 2.5vw, 4rem); 
    max-width: 28vw;
    /* background-color: red; */
}

.each_Ratio_BigOverlay div:last-child{
    display: flex;

}

.each_Ratio_BigOverlay div:last-child p{
    margin-left: 4vw;
    margin-right: 4vw;
    font-size: 1vw;
}

/* ........................................................................................... */
.each_Ratio_SmallOverlay{
    width: 16vw;
    height: 14vw;
    border-bottom-left-radius: 21px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    color: var(--color1);
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.each_Ratio_SmallOverlay_titile{
    width: 80%;
    font-weight: bold;
    font-size: 1vw;
}

.each_Ratio_SmallOverlay_details{
    width: 80%;
}

.each_Ratio_SmallOverlay_details div:first-child{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.each_Ratio_SmallOverlay_details div:first-child h1{
    font-size: 5vw;
    margin-left: 2vw;
}

.each_Ratio_SmallOverlay_details div:first-child h3{
    font-size: 1vw;
    margin-top: -0.5vw;
    margin-bottom: 1vw;
}

.each_Ratio_SmallOverlay_details div:first-child p{
    font-size: 1vw;
    margin-bottom: 0.4vw;
}

.sub{
    font-size: 2vw;
}

#ratio_home_profit{
    background-color: var(--color10); 
}

#ratio_home_profit div:last-child{
    margin-top: 1vw;
}

#ratio_home_NetProfit{
    background-color: var(--color5);
}

#ratio_home_FreeCashFlow{
    background-color: var(--color12);
}

#ratio_home_OwnersEquity{
    background-color: var(--color6);
}

@media screen and (max-width: 601px){
    #ratioOverlay{
        border-left: 0px solid var(--color7); 
        border-right: 0px solid var(--color7);
        height: calc(91.2vh - 12.35vw);
    }

    #ratioHomeform{
        margin-top: 3.5vw;
    }

    #ratioHomeform .reverseDiv{
        justify-content: center;
        margin-bottom: 1vw;
        /* border: 2px solid red; */
    }

    #ratioHomeform .reverseDiv div{
        /* width: 90%; */
        width: 87vw;
        display: flex;   
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
    }

    #ratioHomeform .reverseDiv div input{
        padding-left: 0.4vw;
        padding-right: 0.4vw; 
        height: 9vw;
        width: 30%;
        font-size: 0.8em;
    }

    #ratioHomeform .reverseDiv div select{
        width: 16%;
        height: 10vw;
        border-radius: 7px;
        font-size: 0.8em;
        background-color: white;
    }
    
    #ratioHomeform .reverseDiv div option{
        font-size: 0.8em;
    }
    
    #ratioHomeform .reverseDiv p{
        margin-right: 0.3vw;
    }
    
    #filterDateFrom{
        width: 11vw;
        height: 2vw;
        margin-right: 1.9vw;
    }
    
    #filterDateTo{
        width: 11vw;
        height: 2vw;
        margin-right: 1.9vw;
    }
    
    #all_ratios_overlay{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2vw;
        width: 87vw;
    }
    
    .each_Ratio_BigOverlay{
        width: 87vw;
        height: 28vw;
        border-bottom-left-radius: 21px;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
        color: var(--color1);
        margin-bottom: 6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .each_Ratio_BigOverlay div:first-child{
        display: flex;
        justify-content: space-between;
        width: 90%;
        
    }
    
    .each_Ratio_BigOverlay div:first-child h3{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9em;
    }
    
    .each_Ratio_BigOverlay div:first-child  h3 .h3_normal{
        font-size: 0.9em;
        margin-top: 3.5vw;
    }
    
    .each_Ratio_BigOverlay div:first-child h3 .h3_percentage{
        font-size: 2em;
        margin-bottom: 0vw;
    }
    
    .each_Ratio_BigOverlay div:last-child{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.86em;
        width: 90%;
    }
    
    .each_Ratio_BigOverlay div:last-child p {
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 1vw;
        font-size: 0.8em;
    }

    .each_Ratio_BigOverlay div:last-child p .ratio_number_values{
        margin-left: 0.3em;
        word-wrap: break-word;
        word-break: break-word;
    }

    /* ........................................................................................... */
    .each_Ratio_SmallOverlay{
        height: 28vw;
        width: 87vw;
        border-bottom-left-radius: 21px;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
        color: var(--color1);
        margin-bottom: 4vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .each_Ratio_SmallOverlay_titile{
        width: 80%;
        font-weight: bold;
        font-size: 0.8em;
    }
    
    .each_Ratio_SmallOverlay_details{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1vw;
        align-items: flex-end;
    }
    
    .each_Ratio_SmallOverlay_details div:first-child p{
        font-size: 0.8em;
        margin-bottom: 0.4vw;
    }

    .each_Ratio_SmallOverlay_details div:last-child{
        display: flex;
        flex-direction: column;
    }

    .each_Ratio_SmallOverlay_details div:last-child h1{
        font-size: 2.5em;
        margin-left: 2vw;

    }

    .each_Ratio_SmallOverlay_details div:last-child h3{
        font-size: 0.8em;
        margin-bottom: 00px;
    }

    .sub{
        font-size: 0.6em;
    }
    
    #ratio_home_profit div:last-child{
        margin-top: 1vw;
    }
}