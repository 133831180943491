.NavLink{
    text-decoration: none;
    color: var(--color2);
}

#optionsOverlay{
    display: flex;
    height: 100vh;
    width: 0%;
    position: absolute;
    left: 0px;
    z-index: 500;
    /* top: -11.5vw; */
    flex-direction: row-reverse;
    transition: 0.3s;
    background-color: rgba(0, 0, 0,0.2);
}

#optionsList{
    display: flex;
    flex-direction: column;
    height: 90vh;
    height: 100%;
    width: 0%;
    background-color: rgb(247, 247, 247);
    background-color: rgb(0, 0, 0);
    background-image: linear-gradient(180deg, rgb(247, 247, 247), rgb(197, 196, 196));
    background-image: linear-gradient(180deg, #f9fafd, #d4d7e9);
    z-index: 1;
    /* top: -11.5vw; */
    position: fixed;
    right: 0px;
    transition: 0.3s;
    padding-top: 0vw;
    overflow-x: hidden;
    overflow-y: scroll; 
    box-shadow: 7px 0px 20px black;
}

#closeOptions{
    margin-top: 0.7vw;
    width: 3vw;
    height: 3vw;
    margin-left: 1.5vw;
    border: 2px solid var(--color1);
    /* margin-bottom: -2vw; */
}

#closeOptions:hover{
    border: 2px solid var(--color7);
}

#currentUser{
    width: 100%;
    height: 6.5vw;
    display: flex;
    align-items: center;
}

#currentUser:hover{
    color: rgb(92, 91, 91);
}

#currentUser img{
    width: 3.5vw;
    height: 3.5vw;
    margin-left: 1.5vw;
    margin-right: 1vw;
    border-radius: 30px;

    background-image: url(../../icons/mobile_icon/create_user.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#currentUser p{
    max-width: 13vw;
    font-weight: bold;
    font-size: large;
}

#currentUser small{
   font-weight: bold;
   color: rgb(151, 151, 151);
}

.EachOption{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    border-top: 2px solid #bfc2d3;
    /* border-top: 2.5px solid var(--color7); */
    font-weight: bold;
    color: rgb(92, 91, 91);
}

.EachOption:hover{
    color: rgb(73, 73, 73);
    background-color: var(--color7);
}

.EachOption2:hover{
    color: rgb(73, 73, 73);
    background-color: var(--color7);
    background-color: #ced2eb;
}

.eachOptionIcon{
    width: 2vw;
    height: 2vw;
    margin-left: 1.5vw;
    margin-right: 1vw;
    margin: 0.7vw;
}








@media screen and (max-width: 601px){
    #mobile_optionsOverlay{
        display: flex;
        height: 109vh;
        width: 0%;
        position: absolute;
        left: 0px;
        z-index: 500;
        top: -11.5vw;
        flex-direction: row-reverse;
        transition: 0.3s;
        background-color: rgba(0, 0, 0,0.2);
    }
    
    #mobile_optionsList{
        display: flex;
        flex-direction: column;
        height: 109vh;
        width: 0%;
        background-color: rgb(247, 247, 247);
        background-color: rgb(0, 0, 0);
        background-image: linear-gradient(180deg, rgb(247, 247, 247), rgb(197, 196, 196));
        background-image: linear-gradient(180deg, #f9fafd, #d4d7e9);
        z-index: 1;
        top: -11.5vw;
        position: fixed;
        right: 0px;
        transition: 0.3s;
        padding-top: 0vw;
        overflow-x: hidden;
        overflow-y: hidden; 
        box-shadow: 7px 0px 20px black;
    }
    
    #mobile_closeOptions{
        margin-top: 1vw;
        width: 10vw;
        height: 10vw;
        margin-left: 3vw;
        border: 2px solid var(--color1);
        margin-bottom: -2vw;
    }
    
    #mobile_closeOptions:hover{
        border: 2px solid var(--color7);
    }
    
    #mobile_currentUser{
        width: 100%;
        height: 20vw;
        display: flex;
        align-items: center;
    }
    
    #mobile_currentUser:hover{
        color: rgb(92, 91, 91);
    }
    
    #mobile_currentUser img{
        width: 10vw;
        height: 10vw;
        margin-left: 4vw;
        margin-right: 3vw;
        border-radius: 30px;
    
        background-image: url(../../icons/mobile_icon/create_user.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;
    
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    
    #mobile_currentUser p{
        max-width: 50vw;
        font-weight: bold;
        font-size: large;
    }
    
    #mobile_currentUser small{
       font-weight: bold;
       color: rgb(151, 151, 151);
    }
    
    .mobile_EachOption{
        display: flex;
        align-items: center;
        /* justify-content: center; */
        width: 100%;
        border-top: 2px solid #bfc2d3;
        /* border-top: 2.5px solid var(--color7); */
        font-weight: bold;
        color: rgb(92, 91, 91);
    }
    .mobile_EachOption:hover{
        color: rgb(73, 73, 73);
        background-color: var(--color7);
    }
    
    .mobile_eachOptionIcon{
        width: 6vw;
        height: 6vw;
        margin: 2vw;
        margin-left: 4vw;
        margin-right: 3vw;
    }
}   