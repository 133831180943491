.overlay{
    width: 100%;
}

#row1_scroll_overlay {
    height: calc(100vh - 12.35vw);
    background-color: var(--color1);
    border-left: 3px solid var(--color7); 
    display: flex;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
}

#row1_scroll_overlay::-webkit-scrollbar{
    height: 1vw;
    /* width: 0.8vw; */
    width: 0.4vw;
}

/* #row1_scroll_overlay::-webkit-scrollbar-track{
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(223, 217, 217);
} */

#row1_scroll_overlay::-webkit-scrollbar-thumb{
    background-color: var(--color8);
    border-radius: 10px;
}

#row1_scroll_overlay::-webkit-scrollbar-corner{
    background-color: darkgray;
}

#row1 {
    /* width: 50%; */
    height: 92vh;
    background-color: var(--color1);
    border-left: 3px solid var(--color1); 
    border-right: 3px solid var(--color7); 
    /* border-right: 3px solid var(--color4);  */
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
	align-items: center;
    width: 100%;
    /* overflow: scroll; */
    direction: ltr;
}
/* #row2 {
    width: 100%;
    height: 13vh;
    background-color: var(--color2);
}  */

#big_table_overlay{
    overflow: hidden;
    border-top-left-radius: 39px;
    border-top-right-radius: 39px;
    padding: 0;
    /* margin-top: 6vh; */
    margin-top: 2.8vw;
}

#table_overlay{
    border: 2px solid black;
    border-top-left-radius: 39px;
    border-top-right-radius: 39px;
    border-spacing: 0;
    overflow: hidden;
    width: 61.1vw;
    /* height: 50.95vh; */
    max-height: 23.99vw;
    /* margin: -0.62px; */
    /* transition: 0.3s; */
}

#table-ledger { 
    margin: 0px;   
    /* background-color: red; */
    /* border: 10px solid ; */
    /* padding-bottom: ; */
}

#thead{
    position: sticky;
    top: 0;
    /* height: 14.5vh; */
    /* height: 7.25vw; */
    /* height: 6.8vw; */
    overflow-y: scroll;
    overflow-y: overlay;
    overflow-x: scroll;
    overflow-x: overlay;
    
}

#thead::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

/* #stream{
    min-height: 3.1vw;
    background-color: green;
    border: 2px solid red;
} */

#tbody{
    /* overflow: auto; */
    /* height: 36.5vh; */
    max-height: 16.6vw;
    overflow-y: scroll;
    overflow-y: overlay;
    overflow-x: scroll;
    
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    /* transition: 0.3s; */
    
    /* transform:rotateX(180deg); */
    /* -moz-transform:rotateX(180deg); Mozilla */
    /* -webkit-transform:rotateX(180deg); Safari and Chrome */
    /* -ms-transform:rotateX(180deg); IE 9+ */
    /* -o-transform:rotateX(180deg); */
    /* overflow: overlay; */
}


#tbody::-webkit-scrollbar{
    height: 1vw;
    width: 1vw;
}

#tbody::-webkit-scrollbar-track{
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(223, 217, 217);
    /* outline: 1px solid rgb(223, 217, 217); */
    /* border-left: 2px solid black; */
}

#tbody::-webkit-scrollbar-thumb{
    background-color: var(--color8);
    /* outline: 1px solid rgb(223, 217, 217); */
    border-radius: 10px;
    border: 0.2vw solid rgb(223, 217, 217);
    /* border-left: 2px solid black; */
}

#tbody::-webkit-scrollbar-corner{
    background-color: darkgray;
}

.th{
    display: flex;
    background-color: var(--color8);
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
    /* font-size: 0.9vw; */
    font-size: 1vw;
    font-weight: bolder;
    color: var(--color1);
}

.th_stream{
    /* height: 8vh; */
    /* height: 3.65vw; */
    height: 4vw;

}
.tr{
    display: flex;
    flex-direction: row;
}

#period{
    font-size: 1.6vw;
    /* width: var(--periodWidth); */
}

#period div:first-child span{
    font-size: 0.8vw;
    margin-right: 0.3vw;
}

#period div:first-child input{
    /* font-size: 0.8vw; */
    border: 1px solid var(--color1);
    background-color: var(--color8);
    margin-right: 2.5vw;
    width: 8vw;
    color: var(--color1);
    padding-left: 8px;
    height: 2vw;
    font-size: 0.78vw;
}

#period div:last-child span{
    font-size: 0.8vw;
    margin-right: 0.3vw;
}

#period div:last-child input{
    /* font-size: 0.8vw; */
    border: 1px solid var(--color1);
    background-color: var(--color8);
    margin-right: 0vw;
    width: 8vw;
    color: var(--color1);
    padding-left: 8px;
    height: 2vw;
    font-size: 0.78vw;
}

.stream{
    font-size: 1.6vw;
    padding-left: 5px;
    padding-right: 5px;
}

#trSecondRow{
    height: 3.3vw;
}

#addTr{
    /* height: 6.2vh; */
    height: 3.1vw;
    display: flex;
    flex-direction: row;
    position: sticky;
    bottom: 0;
    background-color: var(--color1);
    z-index: 2;
}

.td{
    align-items: center;
    justify-content: center;
    display: flex;
    /* min-height: 6.2vh; */
    min-height: 3.1vw;
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    /* hyphens: auto;  */
    font-size: 0.9vw;
    background-color: var(--color1);
    width: var(--flowWidth);
}

.sticky_offset{
    z-index: 1;
}

.ledgerSn{
    width: var(--snWidth);
}

.ledgerDates{
    width: var(--dateWidth);
}

.discriptionTd{
    justify-content: left;
    width: var(--descriptionWidth);
}

.discriptionTd  button{
    margin-left: 30px;
    font-size: 1vw;
    background-color: var(--color1);
    border: 0px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    height: 90%;
}

.discriptionTd  button:hover{
    background-color: var(--color8);
    color: var(--color1);
    border: 2px solid black;
}

.discriptionTd  button:active{
    background-color: var(--color8);
    border: 2px solid black;
}

.discriptionTd  button span{
    /* background-color: red; */
    width: 1.4vw;
    /* height: 2.7vh; */
    height: 1.35vw;
    margin-right: 0.3vw;

    background-image: url(../../icons/add_circle.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;

     /* -webkit-filter : hue-rotate(180deg); */
    /* filter : hue-rotate(180deg); */
    /* filter: invert(100%); */
    /* filter: saturate(3); */
    /* filter: grayscale(100%); */
}

#EditDeleteOverlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3vw;
    /* height: 6.5vh; */
    height: 3.25vw;
    position: absolute;
    margin-left: 56.8vw;
    padding-left: 2vw;
    z-index: 50  ;
    /* background-color: blue; */
}

#EditDeleteOverlay button{
    background-color: white;
    border: 1px solid white;
    
    width: 1.3vw;
    /* height: 6vw; */
    /* border-radius: 7px; */
}

#EditDeleteOverlay button:hover{
    background-color: gray;
}

#EditDeleteOverlay button:active{
    background-color: var(--baground1);
    color: white;
}

#EditDeleteOverlay #deleteBtn{
    background-image: url(../../icons/closeSquare_dark.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}
#EditDeleteOverlay #deleteBtn{
    background-color: white;
    background-image: url(../../icons/closeSquare_hover.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

#EditDeleteOverlay .editBtn{
    background-color: white;
    background-image: url(../../icons/edit_btn.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

#EditDeleteOverlay .editBtn:hover{
    background-color: white;
    background-image: url(../../icons/edit_btn_hover.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}


.streamHeader{
    display: flex;
    /* height: 100%; */
    height: 3.3vw;
    /* width: var(--streamWidth); */
}

.streamHeader div{
    width: var(--flowWidth);
}

#totals{
    /* margin-top: 3vh; */
    margin-top: 1.3vw;
    /* border: 2px solid red; */
    /* background-color: blueviolet; */
    /* width: 62%; */
    /* width: 85%; */
    /* width: 84.9%; */
    width: calc(84.9% - 2px);
    /* width:  61vw; */
    display: flex;
    /* flex-direction: ; */
    align-items: flex-end;
    /* margin-left: 1vw; */
    /* overflow-x: scroll; */
    overflow-x: hidden;
    flex-direction: column;
}

#balances_overlay{
    /* border: 2px solid orange; */
    display: flex;
    /* width: 51%; */
    /* margin-left: 3vw; */
    /* margin-left: 1vw; */
    /* overflow: hidden; */
    /* overflow: scroll; */
    /* overflow-x: scroll; */
    overflow: hidden;
    width: 100%;
    flex-direction: row;
    border-right: 2px solid black;
    height: 7.4vw;
}

/* #totalBalance1{
    border-right: 2px solid green;
    width: 90%;
}

#totalBalance2{
    border-right: 2px solid green;
    width: 50%;
} */


#total_balance_dummyBlock{
    background-color: white;
    /* background-color: greenyellow; */
    border: 0px solid black;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 7.4vw;
    /* width: calc(var(--snWidth) + var(--dateWidth) + var(--descriptionWidth) + +1.29999vw); */
    width: calc(var(--snWidth) + var(--dateWidth) + var(--descriptionWidth) + 1.29999vw);
}

#total_balance_dummyBlock p{
    /* margin-top: 2vh; */
    margin-top: 1vw;
    /* margin-bottom: 2vh; */
    margin-bottom: 1vw;
    margin-right: 1.6vw;
    font-size: 1vw;
    /* color: var(--color1); */
}

#balances_overlay::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

#grand_Total_overlay{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* background-color: brown; */
    margin-bottom: 1vw;
}

#grand_Total_overlay p{
    margin-top: 2vh;
    margin-top: 1vw;
    margin-bottom: 2vh;
    margin-bottom: 1vw;
    margin-right: 1.6vw;
    font-size: 1vw; 
}


#grand_Total{
    background-color: var(--color10);
    width: 51.18%;
    /* height: 7vh; */
    height: 3.5vw; 
    /* margin-top: 3vh; */
    margin-top: 1.5vw;
    border-radius: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;   
}

#grandTotal_text{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
}


@media screen and (max-width: 601px){
    :root {
        /* ledger mobile........................................*/
        --streamWidth: 24vw;
        --periodWidth: 30vw;
      
        --flowWidth: calc(var(--mobile_streamWidth) / 2);
        /* --mobile_snWidth: calc(var(--mobile_periodWidth) * 0.1); */
        --dateWidth: calc(var(--mobile_periodWidth) * 0.3);
        --descriptionWidth: calc(var(--mobile_periodWidth) * 0.7);
    }

    .desktop{
        display: none !important;
    }

    .mobile{
        display: flex !important;
    }    

    #ledger_section{
        height: 100%;
        /* border-left: 3px solid blue;  */
    }
    
    #row1_scroll_overlay {
        border-left: 0px solid var(--color4); 
        height: 83.2vh;
    }

    #row1 {
        border-left: 0px solid var(--color1); 
        border-right: 0px solid var(--color4); 
    }

    #big_table_overlay{
        border-top-left-radius: 0px;    
        border-top-right-radius: 0px;
    }

    #table_overlay{
        border: 0.5px solid black;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        width: 96vw;
        /* height: 62.3vh; */
        max-height: 100%;
        transition: 0.3s;
    }

    #tbody{
        max-height: 76.7vw;
        transition: 0.3s;
    }

    #tbody::-webkit-scrollbar{
        height: 1vw;
        width: 1vw;
    }
    
    #tbody::-webkit-scrollbar-track{
        box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(223, 217, 217);
        /* outline: 1px solid rgb(223, 217, 217); */
        /* border-left: 2px solid black; */
    }
    
    #tbody::-webkit-scrollbar-thumb{
        border-radius: 10px;
        border: 0.2vw solid rgb(223, 217, 217);
    }
    
    .th{
        border: 0.5px solid black;
        font-size: 0.65em;
        text-align: center;
        word-wrap: break-word;
        word-break: break-word;
    }
    
    .th_stream{
        height: 10vw;
        word-wrap: break-word;
        word-break: break-word;
    }
    
    #period{
        /* font-size: 1.6vw; */
        /* font-size: 1.2em; */
        height: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    #period div:first-child span{
        /* font-size: 0.8vw; */
        font-size: 1.2em;
        margin-right: 0.3vw;
    }
    
    #period div:first-child input{
        /* border: 0.5px solid var(--color1); */
        /* margin-right: 2.5vw; */
        margin-bottom: 0.5vw;
        width: 20vw;
        padding-left: 8px;
        height: 3vw;
        font-size: 0.78vw;
        font-size: 1.2em;
    }
    
    #period div:last-child span{
        font-size: 0.8vw;
        font-size: 1.2em;
        margin-right: 0.3vw;
    }
    
    #period div:last-child input{
        /* margin-right: 0vw; */
        margin-top: 0.5vw;
        width: 20vw;
        padding-left: 8px;
        height: 3vw;
        font-size: 0.78vw;
        font-size: 1.2em;
    }
    
    .stream{
        font-size: 3.6vw;
        font-size: 1em;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    #trSecondRow{
        height: 100%;
        height: 8vw;
    }
    
    #addTr{
        height: 8vw;
        z-index: 2;
    }
    
    .td{
        min-height: 8vw;
        border: 0.5px solid black;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.9vw;
        font-size: 0.65em;
        width: var(--mobile_flowWidth);
        word-wrap: break-word;
        word-break: break-word;
    }
    
    .ledgerSn{
        width: var(--mobile_snWidth);
    }
    
    .ledgerDates{
        width: var(--mobile_dateWidth);
        word-wrap: normal;
        word-break: keep-all;
    }
    
    .discriptionTd{
        width: var(--mobile_descriptionWidth);
        
    }
    
    #mobileAddLedger{
        /* background-color: red; */
        /* margin-left: 30px; */
        font-size: 1vw;
        font-size: 1em;
        border: 0px;
        /* padding: 10px; */
        border-radius: 5px;
        display: flex;
        align-items: center;
        /* text-align: center; */
        text-decoration: none;
        /* justify-content: center; */

    }
    
    .mobileAddLedger:hover{
        border: 2px solid black;
    }
    
    .mobileAddLedger:active{
        border: 2px solid black;
    }

    .discriptionTd span{
        width: 4.4vw;
        height: 4.35vw;
        margin-right: 0.3vw;

        background-image: url(../../icons/add_circle.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;

        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    
    
    #EditDeleteOverlay{
        width: 23vw;
        height: 8vw;
        margin-left: 70vw;
        /* margin-top: -2vw; */
        padding-left: 0vw;
        /* background-color: blue; */
        flex-direction: row;
        align-items: center;
        justify-content: center;
    } 
    
    #EditDeleteOverlay button{
        border: 1px solid white;
        
        width: 7vw;
        height : 7vw;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    
    .streamHeader{
        height: 8vw;
    }
    
    .streamHeader div{
        width: var(--mobile_flowWidth);
    }
    
    #totals{
        margin-top: 1.3vw;
        /* width: calc(84.9% - 2px); */
        width: calc(97%); 
        /* border: 1px solid red; */
    }
    
    #balances_overlay{
        width: 100%;
        border-right: 1px solid black;
        height: 18.6vw;
        /* border: 1px solid black; */
    }
    
    #total_balance_dummyBlock{
        border: 0px solid black;
        border-right: 1px solid black;
        height: 18.31vw;
        width: calc( var(--mobile_dateWidth) + var(--mobile_descriptionWidth)  + 3.7vw );
        /* border: 1px solid black; */
    }
    
    #total_balance_dummyBlock p{
        margin-top: 2vw;
        margin-bottom: 3vw;
        margin-right: 1.6vw;
        font-size: 1vw;
        font-size: 0.9em;
    }
    
    #grand_Total_overlay{
        width: 100%;
        margin-bottom: 1vw;
    }
    
    #grand_Total_overlay p{
        margin-top: 2vh;
        margin-top: 1vw;
        margin-bottom: 2vh;
        margin-bottom: 1vw;
        margin-right: 1.6vw;
        font-size: 1vw; 
        font-size: 0.6em;
    }
    
    
    #grand_Total{
        width: 62.5%;
        height: 6.5vw;
        margin-top: 1.5vw;
        border-radius: 20px;
    }
}

@media screen and (max-width: 1042px){
    #row1_scroll_overlay {
        /* height: 100%; */
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

@media screen and (min-width: 1750px){

}