#mobileProfile_overlay{
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    width: 100vw;
    height: 0%;
    z-index: 500;
    transition: 0.3s;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0px;
}


#mobileProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0vw;
    max-height: 0;
    min-height: 0;
    width: 100vw;
    padding-top: 0vw;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.3s;
}

#mobileProfile::-webkit-scrollbar{
    height: 1vw;
    width: 2vw;
}

#mobileProfile::-webkit-scrollbar-thumb{
    background-color: var(--color8);
    border-radius: 10px;
}

#mobileProfile::-webkit-scrollbar-corner{
    background-color: darkgray;
}

#mobileProfile button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85vw;
    margin-bottom: 3vw;
    border: 0px solid white;
}

#mobileProfile div:first-child{
    color: var(--color2);
    font-size: medium;
    text-align: center;
    display: flex;
    align-items: center;
}

#mobileProfile div:first-child img{
    width: 9.5vw;
    height: 9.5vw;
    border-radius: 25px;
    margin-right: 5vw;
    margin-left: 1vw;
    
}

#mobileProfile button input{
    width: 4vw;
    margin-right: 1vw;
}