footer{
    background-color: var(--color4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color1);
    height: 10vh;
    height: 4vw;
    position: fixed;
    bottom: 0;
    width: 100%;
}