.subNavActive{
    /* background-color: var(--color1) !important; */
    /* background-color: var(--color1) ; */
    border-bottom: 8px solid var(--color5);
}

.subNavActive p{
    color: var(--color5);
    /*  background-color: aqua; */
}

.NavLink{
    text-decoration: none;
    color: var(--color2);
}

#subNav{
    /* height: 8.7vh; */
    height: 4.35vw;
    border-bottom: 3px solid var(--color7);
    width: 100%;
    background-Color: var(--color1);
    text-align: center;
    display: flex;
    /* flex-direction: column; */
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    font-size: 0.85vw;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 500;
}

.subNav_selected{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
    /* border: 2px solid black; */
    color: inherit;
    /* background-color: red; */
    /* z-index: 1; */
}

/* .subNav_selected:hover{
    background-color: var(--color5);
    color: var(--color1);
} */

#subNav_overlay{
    margin-left: 0.9vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid rgb(56, 52, 52); */
    width: 78%;
} 

#subNav div div p{
    margin-top: 0.3vh;
}

#subNav_recurring_record{
    background-image: url(../../icons/recurring_record_dark.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 1.5vw;
    /* height: 3vh; */
    height: 1.5vw;
    display: flex;
    justify-content: center;
}

/* #subNav_recurring_record:hover{
    background-image: url(../../icons/recurring_record_hover.png);
}

#subNav_recurring_record:active{
    background-image: url(../../icons/ratio_hover.png);
} */


#subNav_convert_to_pdf{
    background-image: url(../../icons/convert_to_pdf_dark_icon.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 1.5vw;
    height: 3vh;
    height: 1.5vw;
    display: flex;
    justify-content: center;
}

/* #subNav_convert_to_pdf:hover{
    background-image: url(../../icons/ratio_hover.png);
}

#subNav_convert_to_pdf:active{
    background-image: url(../../icons/ratio_hover.png);
} */

#subNav_graphs{
    background-image: url(../../icons/graph_dark_icon.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 1.5vw;
    height: 3vh;
    height: 1.5vw;
    display: flex;
    justify-content: center;
}


/* #subNav_graphs:hover{
    background-image: url(../../icons/ratio_hover.png);
}

#subNav_graphs:active{
    background-image: url(../../icons/ratio_hover.png);
} */

#subNav_ratios{
    background-image: url(../../icons/ratio_dark.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 1.5vw;
    height: 3vh;
    height: 1.5vw;
    display: flex;
    justify-content: center;
}

/* #subNav_ratios:hover{
    background-image: url(../../icons/ratio_hover.png);
}

#subNav_ratios:active{
    background-image: url(../../icons/ratio_active.png);
} */

#subNav_filtter{
    background-image: url(../../icons/filter_dark.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 1.5vw;
    height: 3vh;
    height: 1.5vw;
    display: flex;
    justify-content: center;
}

/* #subNav_filtter:hover{
    background-image: url(../../icons/filter_hover.png);
}

#subNav_filtter:active{
    background-image: url(../../icons/filter_active.png);
} */

#subNav_settings{
    background-image: url(../../icons/settings_dark_icon.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    justify-content: center;
}

/* #subNav_settings:hover{
    background-image: url(../../icons/ratio_hover.png);
}

#subNav_settings:active{
    background-image: url(../../icons/ratio_hover.png);
} */ 

#search_overlay{
    width: 38%;
    display: flex;
    margin-right: 2vw;
}

#subNav div input{
    width: 90%;
    height: 2.3vw;
    padding-left: 3%;
    margin-left: 3.5vw;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0;
    border-right: 0px;
    font-size: 1vw;
    font-weight: bold;
}

#subNav div input:focus{
    
    /* border: 2px solid black; */
    /* border-right: 0px solid green; */
    outline: 0px;
    /* outline */
    background-color: rgb(245, 245, 245);
}

#search_icon_overlay{
    width: 10%;
    height: 2.37vw;
    border: 2px solid var(--color7);
    display: flex;
    align-items: center;
    border-left: 0px;
    border-bottom-right-radius: 9px;
    border-top-right-radius: 9px;
}

#search_icon{
    width: 1.3vw;
    height: 1.3vw;

    background-image: url(../../icons/search_icon.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

@media screen and (max-width: 601px){
    /* .active{
        border-bottom: 0px solid var(--color5);
        border: 1.5px solid white;
        border-radius: 13px;
    }
    
    .active p{
        color: var(--color1);
    } */

    #mobileSubNav{
        background-color: var(--color4);
        width: 100%;
        height: 9vh;
        min-height: 16vw;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.85vw;
        font-size: 0.6em;
        font-weight: bold;
        position: sticky;
        bottom: 0;
        /* z-index: 500; */
        /* padding-left: 5%; */
        /* padding-right: 5%; */
    }

    .NavLink{
        color: white;
    }
    
    .subNav_icon_div{
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .subNav_selected{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        padding-top: 1vh;
        padding-bottom: 1vh;
        padding-left: 0.7vw;
        padding-right: 0.7vw;
        color: inherit;
        
    }

    .subNav_selected p{
        /* width: 15vw; */
        margin-top: 0.3vh;
    }
    
    #subNav_convert_to_pdf{
        background-image: url(../../icons/mobile_icon/convert_to_pdf_white.png);
        width: 7vw;
        height: 7vw;
    }
    
    #subNav_graphs{
        background-image: url(../../icons/mobile_icon/graph_white.png);
        width: 7vw;
        height: 7vw;
    }
    
    #subNav_ratios{
        background-image: url(../../icons/mobile_icon/ratio_white.png);
        width: 7vw;
        height: 7vw;
    }
    
    #subNav_filtter{
        background-image: url(../../icons/mobile_icon/filter_white.png);
        width: 7vw;
        height: 7vw;
    }
    
    #subNav_add{
        background-image: url(../../icons/mobile_icon/add_white.png);
        width: 7vw;
        height: 7vw;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;

        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        display: flex;
        justify-content: center;
    }

    #subNav_cash_book{
        background-image: url(../../icons/cashbook_white.png);
        width: 7vw;
        height: 7vw;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;

        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

@media screen and (min-width: 1750px){

}