.overlay{
    width: 100%;
}

h3{
    color: var(--color8);
    margin-bottom: 1vw;
}

.buttonLeft_overlay{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

#businessProfileSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3f2f7;
    height: calc(100vh - 12.35vw);
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    transition: 0.3s;
}

#businessProfile_flag{
    background-color: var(--color5);
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-direction: row;
    color: var(--color1);
    box-shadow: 0px -4.5px 22px black;
}

#businessProfile_flag div:first-child{
    width: 10vw;
    height: 100%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#businessProfile_flag img:first-child{
    width: 2vw;
    height: 2vw;
    margin-bottom: 4vw;
}

#businessProfile_flag img:last-child{
    width: 10vw;
    height: 10vw;
    background-color: white;
    border-radius: 50%;
    margin-bottom: -2.5vw;
    box-shadow: 0px -0px 1px black;
    z-index: 1;
}

#businessProfile_flag h1{
    width: 43vw;
    margin-left: 6vw;
    margin-top: 2.5vw;
    font-weight: bold;
    font-size: 1.8vw;
}

#businessProfile_progress{
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 45vw; */
    width: 95.3%;
    border-bottom: 2px solid var(--color7);
    margin-bottom: 2vw;
}


#businessProfile_progressBar{
    background-color: var(--color1);
    height: 1.1vw;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1.5vw;
}

#businessProfile_progressBar div{
    background-color: var(--color7);
    height: 1.1vw;
    width: 50%;
    border-radius: 5px;
}


#businessProfile_progress p{
    width: 95.3%;
    margin-bottom: 1vw;
}

#businessProfile_progress p b{
    display: block;
}

#businessProfile_progress button{
    width: 7vw;
    height: 2vw;
    font-size: 0.88em; 
    font-weight: bold;
    background-color: var(--color5);
    color: var(--color1);
    margin-bottom: 1vw;
    left: 0;
}

#businessProfile_progress button:hover{
    color: var(--color5);
    background-color: var(--color1);
}

#companyInfo{
    width: 95.3%;
    border-bottom: 2px solid var(--color7);
    margin-bottom: 1vw;
} 

#companyInfo button{
    width: 5vw;
    height: 2vw;
    font-size: 0.88em; 
    font-weight: bold;
    background-color: var(--color5);
    color: var(--color1);
    margin-bottom: 1vw;
}

#companyInfo button:hover{
    color: var(--color5);
    background-color: var(--color1);
}


#companyInfo p{
    margin-bottom: 1vw;
}

#companyInfo b{
    display: block;
}

#ManageUsers{
    width: 95.3%;
    border-bottom: 2px solid var(--color7);
    margin-bottom: 5vw;
}

#ManageUsers h4{
    margin-bottom: 4vw;
}

#ManageUsers p{
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 1vw;
}

#ManageUsers button{
    width: 10vw;
    height: 7vw;
    font-size: 0.88em; 
    font-weight: bold;
    background-color: var(--color5);
    color: var(--color1);
    margin-top: 2vw;
    margin-bottom: 4vw;
}

#ManageUsers button:hover{
    color: var(--color5);
    background-color: var(--color1);
}

#userInfo{
    width: 95.3%;
    margin-bottom: 20vh;
    border-bottom: 2px solid var(--color7);
}

#userInfo button{
    width: 20vw;
    height: 7vw;
    font-size: 0.88em; 
    font-weight: bold;
    background-color: var(--color5);
    color: var(--color1);
    margin-bottom: 4vw;
}

#userInfo button:hover{
    color: var(--color5);
    background-color: var(--color1);
}

#userInfo p{
    margin-bottom: 4vw;
}

#userInfo b{
    display: block;
}








@media screen and (max-width: 601px){
    h3{
        color: var(--color8);
        margin-bottom: 5vw;
    }
    
    .buttonLeft_overlay{
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
    }
    
    #businessProfileSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f3f2f7;
        /* background-color: red; */
        height: 93vh;
        /* overflow-y: scroll; */
        /* overflow-x: hidden; */
        transition: 0.3s;
        width: 100vw;
        /* z-index: 500; */
        /* z-index: 1; */
        /* width: 100vw; */
        /* position: absolute; */
        right: 0px;
    }
    
    #section::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    
    #businessProfile_flag{
        background-color: var(--color5);
        width: 100vw;
        height: 45vw;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        display: flex;
        flex-direction: row;
        color: var(--color1);
        box-shadow: 0px -4.5px 22px black;
    }
    
    #businessProfile_flag div:first-child{
        width: 30%;
        height: 50vw;
        padding-left: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #businessProfile_flag img:first-child{
        width: 20%;
        height: 20%;
    }
    
    #businessProfile_flag img:last-child{
        width: 27vw;
        height: 27vw;
        background-color: white;
        border-radius: 50%;
        margin-bottom: -2.5vw;
        box-shadow: 0px -0px 1px black;
        z-index: 1;
    }
    
    #businessProfile_flag h1{
        width: 54vw;
        margin-left: 6vw;
        margin-top: 7vw;
        font-weight: bold;
        font-size: 5.1vw;
    }
    
    #businessProfile_progress{
        margin-top: 12vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* height: 45vw; */
        width: 94vw;
        border-bottom: 2px solid var(--color7);
        margin-bottom: 5vw;
    }
    
    
    #businessProfile_progressBar{
        background-color: var(--color1);
        height: 2.5vw;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 4vw;
    }
    
    #businessProfile_progressBar div{
        background-color: var(--color7);
        height: 2.5vw;
        width: 50%;
        border-radius: 5px;
    }
    
    
    #businessProfile_progress p{
        width: 95%;
        margin-bottom: 4vw;
    }
    
    #businessProfile_progress p b{
        display: block;
    }
    
    #businessProfile_progress button{
        width: 40vw;
        height: 7vw;
        font-size: 0.88em; 
        font-weight: bold;
        background-color: var(--color5);
        color: var(--color1);
        margin-bottom: 4vw;
    }
    
    #businessProfile_progress button:hover{
        color: var(--color5);
        background-color: var(--color1);
    }
    
    #companyInfo{
        width: 94vw;
        border-bottom: 2px solid var(--color7);
        margin-bottom: 5vw;
    } 
    
    #companyInfo button{
        width: 20vw;
        height: 7vw;
        font-size: 0.88em; 
        font-weight: bold;
        background-color: var(--color5);
        color: var(--color1);
        margin-bottom: 4vw;
    }
    
    #companyInfo button:hover{
        color: var(--color5);
        background-color: var(--color1);
    }
    
    
    #companyInfo p{
        margin-bottom: 4vw;
    }
    
    #companyInfo b{
        display: block;
    }
    
    #ManageUsers{
        width: 94vw;
        border-bottom: 2px solid var(--color7);
        margin-bottom: 5vw;
    }
    
    #ManageUsers h4{
        margin-bottom: 4vw;
    }
    
    #ManageUsers p{
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        margin-bottom: 1vw;
    }
    
    #ManageUsers button{
        width: 20vw;
        height: 7vw;
        font-size: 0.88em; 
        font-weight: bold;
        background-color: var(--color5);
        color: var(--color1);
        margin-top: 2vw;
        margin-bottom: 4vw;
    }
    
    #ManageUsers button:hover{
        color: var(--color5);
        background-color: var(--color1);
    }
    
    #userInfo{
        width: 94vw;
        margin-bottom: 20vh;
        border-bottom: 2px solid var(--color7);
    }
    
    #userInfo button{
        width: 20vw;
        height: 7vw;
        font-size: 0.88em; 
        font-weight: bold;
        background-color: var(--color5);
        color: var(--color1);
        margin-bottom: 4vw;
    }
    
    #userInfo button:hover{
        color: var(--color5);
        background-color: var(--color1);
    }
    
    #userInfo p{
        margin-bottom: 4vw;
    }
    
    #userInfo b{
        display: block;
    }
}