.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Modal {
    width: 38vw;
    height: 83vh;
    /* height: 41.4vw; */
    /* height: 36vw; */
    height: 78vh;
    background-color: var(--color1);
    border-radius: 10px;
    transition: display 4s;
    /* box-shadow: 10px 10px 5px var(--color8); */
    box-shadow:
  0px 0px 1px rgba(0, 0, 0, 0.093),
  0px 0px 2.6px rgba(0, 0, 0, 0.133),
  0px 0px 5.3px rgba(0, 0, 0, 0.167),
  0px 0px 11px rgba(0, 0, 0, 0.207),
  0px 0px 30px rgba(0, 0, 0, 0.3)
;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: 600ms filter ease-in-out, 800ms opacity ease-out;
    
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.row{
    display: flex;
    flex-direction: row;
}

.column{
    display: flex;
    flex-direction: column;
}

form{
    justify-content: center;   
    font-size: 0.77vw; 
}

input{
    border-radius: 9px; 
    height: 2.3vw;
    border: 2.5px solid var(--color7);
    font-size: 0.8vw;
}

button{
    background-color: var(--color1);
    border: 2.5px solid var(--color5);
    width: 6vw;
    height: 4.5vh;
    height: 2.3vw;
    border-radius: 4px;
    font-size: 0.8vw; 
    color: var(--color2);
}
/* 
button:focus{
    background-color: var(--color5);
    color: white;
} */

option{
    color: black;
}

#selectedOption{
    color: gray;
}

#record_Header_Overlay{
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6.5vh;
    height: 3.3vw;
    border-bottom: 2px solid var(--color5);
    font-size: 0.7vw;
}

#record_Header_Overlay h2{
    margin-left: 6%;
}

#record_Header_Overlay button{
    margin-right: 6%;
    width: 1.4vw;
    height: 2.8vh;
    height: 1.4vw;
    border-radius: 9px;
}

#descriptionOverlay{
    margin-top: 3vh;
    margin-top: 1.5vw;
    width: 90%;
}

#descriptionOverlay input{
    /* margin-top: 0.7vh; */
    margin-top: 0.35vw;
    width: 94%;
    padding-left: 3%;
    padding-right: 3%;
}

#accountOverlay{
    /* margin-top: 2vh; */
    margin-top: 1vw;
    width: 90%;
}

#accountOverlay button{
    border-color: var(--color5);
    color: var(--color5);
    /* margin-top: 1vh; */
    margin-top: 0.5vw;
    margin-left: 0.84vw;
    margin-right: 0vw;
}

/* #accountOverlay button:active{
    background-color: var(--color5);
}

#accountOverlay button:focus{
    background-color: var(--color5);
} */

#AmountOverlay{
    /* margin-top: 1.5vh; */
    margin-top: 0.75vw;
    width: 90%;
}

#AmountOverlay div{
    justify-content: space-between;
    width: 97%;
}

#AmountOverlay div div{
    width: 46%;
    /* border: 2px solid black; */
}

#AmountOverlay div div input{
    /* margin-top: 1vh; */
    margin-top: 0.5vw;
    width: 74.6%;
    margin-left: 4%;
    padding-left: 3%;
    padding-right: 3%;
}

#savedTransactionOverlay{
    margin-top: 1.8vw;
    width: 90%;
    /* height: 4.6vh; */
    height: 2.35vw;
    /* border: 2px solid black; */
}

#savedTransactionOverlay label{
    /* border: 2.5px solid var(--color2); */
    width: 50%;
    
}

#savedTransactionOverlay label select{
    border-radius: 9px;
    height: 100%;
    /* border: 2.5px solid var(--color5); */
    border: 2px solid var(--color7);
    height: 2.5vw;
    width: 100%;
    background-color: white;
    padding-left: 3%;
    padding-right: 6%;
}

#savedTransactionOverlay div{
    margin-left: 12%;
    border-radius: 9px;
    background-color: var(--color1);
    /* border: 2.5px solid var(--color2); */
    width: 6vw;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

#savedTransactionOverlay div button{
    background-color: var(--color1);
    border: 0px solid var(--color2);
    width: 1.4vw;
    height: 1.4vw;
    border-radius: 4px;
    background-image: url(../../../icons/saveTransaction_icon.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

#savedTransactionOverlay div button:hover{
    border: 2.5px solid var(--color5);
}

#categoryOverlay{
    margin-top: 3.3vh;
    margin-top: 1.7vw;
    width: 90%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    /* border: 1px solid var(--color2); */
}

#categoryOverlay label{
    width: 40%;
}

#categoryOverlay select{
    border: 2px solid var(--color7);
    background-color: white;
    border-radius: 9px;
    height: 2.5vw;
    width: 100%;
    margin-top: 0.3vh;
    margin-top: 0.15vw;
    flex-wrap: wrap;
    padding-left: 0%;
}



#addCategoryOverlay{
    /* margin-top: 2.5vh; */
    /* margin-top: 1.25vw; */
    /* width: 90%; */
    width: 40%;
    /* border: 1px solid var(--color2); */
    margin-left: 2.5vw;
}

#addCategoryOverlay input{
    width: 79%;
    margin-top: 0.8vh;
    margin-top: 0.4vw;
    padding-left: 3%;
    padding-right: 3%;
}

#addCategoryOverlay button{
    border: 0px;
    width: 1.3vw;
    /* height: 2vw; */
    height: 1.3vw;
    border-radius: 9px;
    margin-left: 1%;
    background-image: url(../../../icons/add_circle.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

#addCategoryOverlay button:hover{
    border: 2.5px solid var(--color5);
}

#BTN_overlay{
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    /* border: 1px solid var(--color2); */
    margin-top: 5vh;
    margin-bottom: 5.4vh;
    margin-top: 2.5vw;
    margin-bottom: 2.7vh;
}

#BTN_overlay div{
    margin-right: 2vw;
}

#BTN_overlay div button{
    margin-left: 1.4vw;
}

@media screen and (max-width: 601px){
    input{
        border-radius: 9px; 
        height: 8.3vw;
        border: 2px solid var(--color7);
        font-size: 3.5vw; 
        height: 10vw;
        font-size: 0.88em; 
    }
    
    button{
        background-color: var(--color1);
        border: 2px solid var(--color5);
        width: 20.8vw;
        border-radius: 4px;
        color: var(--color5);
        height: 10vw;
    }

    select{
        height: 10vw;
    }

    #editFormMobileOverlay{
        width: 100%;
        height: 85vh;
        overflow: scroll; 
    }

    #editFormMobile{
        width: 100%;
        background-color: var(--color1);
        justify-content: start;
        font-size: 0.88em; 
    }
    
    #record_Header_Overlay{
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 10vw;
        border-bottom: 2px solid var(--color5);
    }
    
    #record_Header_Overlay h2{
        font-size: 5.5em; 
    }
    
    #record_Header_Overlay button{
        margin-right: 6%;
        width: 6.4vw;
        height: 6.4vw;
        border-radius: 0px;
        border: 0px;

        background-image: url(../../../icons/closeSquare_dark.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;
    
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
         background-size: cover;
    }
    
    #descriptionOverlay{
        margin-top: 4.5vw;
        width: 90%;
    }
    
    #descriptionOverlay input{
        margin-top: 0.35vw;
        width: 94%;
        padding-left: 3%;
        padding-right: 3%;
        font-size: 0.88em; 
    }
    
    #accountOverlay{
        margin-top: 6vw;
        margin-left: 5%;
        width: 95%;        
        font-size: 1em;        
    }

    #accountOverlay_p{
        margin-left: 0px;
        margin-bottom: 1vw;
        font-weight: normal;
        width: 100%;
    }

    #accountOverlay_p:after{
        content:" ";
        height: 1.5px;
        width: calc(95% - 3.95em);
        background: var(--color7);
        display: block;
        position: absolute;
        top: 68%;
        left: 3.95em;      /* change to em when the em font size for this p have been set */
    }

    #accountOverlay div{
        display: flex;
        flex-wrap: wrap;
    }
    
    #accountOverlay button{
        border-color: var(--color5);
        margin-right: 2.5vw;
        margin-left: 0vw;
        margin-bottom: 2vw;
        font-size: 0.88em; 
        word-wrap: break-word;
        word-break: break-word;
    }
    
    
    #AmountOverlay{
        margin-top: 0vw;
        margin-bottom: 3vw;
        width: 90%;
        font-size: 1em; 
    }

    #AmountOverlay_p{
        margin-left: 0px;
        margin-bottom: 0px;
        font-weight: normal;
        width: 100%;
    }

    #AmountOverlay_p:after{
        /* position: absolute; */
        content:" ";
        height: 1.5px;
        /* width: 74.7vw; */
        width: calc(100% - 3.8em);
        background: var(--color7);
        display: block;
        position: absolute;
        top: 68%;
        left: 3.8em;      /* change to em when the em font size for this p have been set */
    }
    
    #AmountOverlay div{
        justify-content: space-between;
        width: 100%;
    }
    
    #AmountOverlay div div{
        width: 50%;
        margin-top: 1vw;
        display: flex;
        flex-direction: column;
    }

    #AmountOverlay div div:last-child{
        align-items: flex-end;
    }
    #AmountOverlay div div:last-child span{
        width: 97%;
    }

    
    #AmountOverlay div div input{
        margin-top: 0vw;
        width: 74.6%;
        width: 89%;
        margin-left: 0%;
        font-size: 0.88em; 
    }

    #savedTransactionOverlay{
        margin-top: 1.8vw;
        width: 90%;
        height: 11vw;
    }
    
    #savedTransactionOverlay label{
        width: 48.2%;
    }
    
    #savedTransactionOverlay label select{
        border-radius: 9px;
        height: 100%;
        border: 2px solid var(--color7);
        width: 100%;
        background-color: white;
        padding-left: 3%;
        padding-right:0%;
        height: 11vw;
    }
    
    #savedTransactionOverlay div{
        margin-left: 5%;
        border-radius: 9px;
        background-color: var(--color1);
        width: 40%;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
    }
    
    #savedTransactionOverlay div button{
        background-color: var(--color1);
        border: 0px solid var(--color2);
        width: 4.4vw;
        height: 4.4vw;
        border-radius: 4px;
        background-image: url(../../../icons/saveTransaction_icon.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;
    
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
         background-size: cover;
    }
    
    #savedTransactionOverlay div button:hover{
        border: 2.5px solid var(--color5);
    }
    
    #categoryOverlay{
        margin-top: 3vw;
        margin-bottom: 4vw;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    #categoryOverlay_p{
        margin-bottom: 0.7vw;
        width: 100%;
    }

    #categoryOverlay_p:after{
        content:" ";
        height: 1.5px;
        width: calc(99% - 4em);
        background: var(--color7);
        display: block;
        position: absolute;
        top: 68%;
        left: 4.2em;      /* change to em when the em font size for this p have been set */
    }
    
    #categoryOverlay label{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    
    #categoryOverlay select{
        border: 2px solid var(--color7);
        background-color: white;
        border-radius: 9px;
        height: 11vw;
        width: 50%;
        flex-wrap: wrap;
        padding-left: 0%;
    }
    
    #addCategoryOverlay{
        width: 50%;
        margin-left: 2.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #addCategoryOverlay input{
        margin-top: 0.0vw;
        padding-left: 3%;
        padding-right: 3%;
        height: 10.2vw;
        font-size: 0.88em; 
    }
    
    #addCategoryOverlay button{
        border: 0px;
        width: 4.3vw;
        height: 4.3vw;
        border-radius: 9px;
        margin-left: 1%;
        background-image: url(../../../icons/add_circle.png);
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: center center;
        background-size: auto;
    
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
         background-size: cover;
    }
    
    #addCategoryOverlay button:hover{
        border: 2.5px solid var(--color5);
    }
    
    #BTN_overlay{
        width: 90%;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 4.5vw;
        margin-bottom: 17.7vw;
    }
    
    #BTN_overlay div{
        margin-right: 2vw;
    }
    
    #BTN_overlay div button{
        margin-left: 1.4vw;
        font-size: 0.88em; 
        font-size: 0.88em; 
    }
}