header{
    color: var(--color1);
}

#navbar1{
    background-color: var(--color4);
    display: flex;
    flex-direction: row;
	justify-content: space-between;
	align-items: center;
    height: 4vw;
} 

#navbarMobile{
    background-color: var(--color4);
    display: flex;
    flex-direction: row;
	justify-content: space-between;
	align-items: center;
    /* padding-top: 3.5vh;
    padding-bottom: 3.5vh; */
    /* height: 10vw; */
    height: 7vh;
    min-height: 10.5vw;
    /* max-height: 10vw; */
    /* border: 1px solid green; */
}  

#navbarMobile #name a{
    font-size: 1.8vw;
}

#name{
    margin-left: 5vw;
}

#name a{
    font-size: 0.55vw;
    text-decoration: none;
    color: var(--text1);
}

ul{
    list-style-type: none;
    margin-right: 8.4vw;
}

#navbarMobile ul{
    display: flex;
    margin-right: 4vw;
}

li{
    display: inline;
    margin-left: 4vw;
    font-size: 1.2vw;
}

li a{
    text-decoration: none;
    color: var(--color1);
}
 

#mobileSearch_overlay{
    right: 0;
    position: absolute;
    width: 0%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 2vw; */
    background-color: var(--color4);
    height: 7vh;
    min-height: 10.5vw;
    border-radius: 5px;
    transition: 0.2s;
    overflow: hidden;
}

#mobileSearch_overlay input{
    width: 82%;
    width: 60%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 7vw;
    padding-left: 8px;
    padding-right: 8px;
    /* height: 6vw;
    background-color: red; */
}

#mobileSearch_overlay #cancleSearch{
    width: 8vw;
    height: 7vw;
    background-color: var(--color1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 2px solid var(--color7);
    border-left: 0px solid white;
    margin-right: 10%;
}

#mobileSearch_overlay img{
    width: 6vw;
    height: 6vw;
}

@media screen and (max-width: 601px){

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

@media screen and (min-width: 1750px){

}