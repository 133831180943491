#profit_scroll_overlay{
    height: calc(100vh - 12.35vw);
    background-color: var(--color1);
    border-left: 3px solid var(--color7); 
    border-right: 3px solid var(--color7); 
    overflow-y: scroll;
    direction: rtl;
    width: 100%;
}

#profit_scroll_overlay::-webkit-scrollbar{
    height: 1vw;
    /* width: 0.8vw; */
    width: 0.4vw;
}

#profit_scroll_overlay::-webkit-scrollbar-thumb{
    background-color: var(--color8);
    border-radius: 10px;
}

#profit_scroll_overlay::-webkit-scrollbar-corner{
    background-color: darkgray;
}

#profitOverlaay{
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: ltr;
}

/* FLAG............................................................................ */
#ratio_Profit_flag{
    margin-top: 4.1vw;
    width: 62.4vw;
    height: 13.8vw;
    background-color: var(--color10); 
    border-bottom-left-radius: 21px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    color: var(--color1);
    display: flex;
    overflow: hidden;
}

#ratio_Profit_flag div{
    padding-left: 1.2vw;
    padding-right: 1vw;
}

#ratio_Profit_flag div:first-child{
    padding-top: 4.2vh;
    padding-left: 6vh;
}

#ratio_Profit_flag div:first-child h3{
    font-size: 1.5vw;
}

#profitImg{
    width: 27vw;
    height: 15vw;
}

#ratio_Profit_flag div:last-child{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1vw;
    margin-left: 1.8vw;
}

#ratio_Profit_flag div:last-child h1{
    font-size: 5vw;
    margin-left: 2.3vw;
}

#sub{
    font-size: 2.3vw;
}

#ratio_Profit_flag div:last-child div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
}

#ratio_Profit_flag div:last-child div:first-child h3{
    margin-top: -1vh;
    margin-right: 4.4vh;
    font-size: 1.1vw;
}

#ratio_Profit_flag div:last-child div:last-child {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 0;
    margin-top: 4.2vh;
}

#ratio_Profit_flag div:last-child span{
    font-size: 0.8vw;
    display: inline-block;
    margin-left: 1.4vw;
    margin-right: 0;
}

/* ratio_Profit_text............................................................................ */
#ratio_Profit_text{
    margin-top: 8vh;
    width: 85%;
}

#ratio_Profit_text p{
    font-size: 1vw;
}

/* ratio table.................................................................................. */
#ratio_table_background{
    margin-top: 3.6vw;
    margin-bottom: 3.6vw;
    width: 62.4vw;
    height: 32.4vw;
    background-color: var(--color7);
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ratio_table{
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    width: 51.2vw;
    max-height: 25.9vw; 
    overflow-y: scroll;
    scrollbar-color: var(--color6) var(--color10);
    scrollbar-width: none;
}

#ratio_table::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

#rato_table_header{
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
}

#rato_table_header div{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    height: 3.1vw;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color1);
}

#headers_date{
    width: 7.7vw;
}

.headers{
    width: 21%;
}

.headers_pecentage{
    width: 11%;
    text-align: center;
}

#rato_table_rows{
    display: flex;
    flex-direction: row;
}

#rato_table_rows div{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    height: 6vh;
    height: 3.1vw;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color1);
    word-wrap: break-word;
    word-break: break-word;
}

#rato_table_rows_date{
    width: 15%;
}

.rato_table_rows{
    width: 21%;
}

.rato_table_rows_pecentage{
    width: 11%;
    text-align: center;
}

#ratioTable_sideBar_overlay{
    width: 1.2vw;
    height: 25.9vw; 
    margin-left: 1.5vw;
    overflow-y: auto;
    overflow-y: overlay;
}

#ratioTable_sideBar{
    width: 0vw;
    background-color: var(--color2);
    margin-top: 6vh;
}

#ratioTable_sideBar_overlay::-webkit-scrollbar{
    height: 1.5vh;
    width: 1.2vw;
}

#ratioTable_sideBar_overlay::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: gray;
    border-radius: 10px;
}

#ratioTable_sideBar_overlay::-webkit-scrollbar-thumb{
    background-color: var(--color7);
    border-radius: 10px;
    border: 0.2vw solid gray;
}

#ratioTable_sideBar_overlay::-webkit-scrollbar-corner{
    background-color: darkgray;
}

/* set period.................................................................................. */
#profitSetPeriod_Overlay{
    display: flex;
    flex-direction: column;
    width: 85%;
    width: 62.4vw;
    margin-bottom: 4vw;
}

#profitSetPeriod_p{
    width: 100%;
    font-weight: bold;
    position: relative;
    margin-bottom: 1.5vw;
    font-size: 1vw;
}

#profitSetPeriod_p:after{
    position: absolute;
    content:" ";
    height: 2px;
    width: calc(100% - 5.4vw);
    background: var(--color7);
    display: block;
    position: absolute;
    top: 60%;
    left: 5.1vw;
}

#profitSetPeriod_formOverlay{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 8vw;
}

#profitSetPeriodBTNS_Overlay{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
    width: 40%;
}

#profitSetPeriodBTNS_Overlay div:first-child{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.75vw;
}

#profitSetPeriodBTNS_Overlay div:last-child{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0.75vw;
}

#profitSetPeriodBTNS_Overlay button{
    width: 40%;
    color: var(--color5);
}

#profitSetPeriodBTNS_Overlay button:hover{
    color: var(--color1);
}

#profitSetPeriodInput_Overlay{
    width: 30%;
    border: 2px solid var(--color7);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin-left: 7vw;
}

#profitSetPeriodInput_Overlay div{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 83%;
    margin-right: 20%;
}

#profitSetPeriodInput_Overlay div input{
    width: 60%;
    margin: 0;
    margin-left: 0.5vw;
    margin-top: 0.75vw;
    margin-bottom: 0.75vw;
    padding-left: 0.5vw;
    padding-right: 0.75vw;

}

/* ...................................................................................................................................................... */
#ratio_slide_BigOverlay{
    width: 54vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vw;
}

#ratio_slide_overlay{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40vw;
    overflow-x: scroll;
    overflow-x: overlay;
}

#ratio_slide_smallOverlay{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#ratio_slide_overlay::-webkit-scrollbar {
    width: 0;  
    background: transparent;  
}

.eachRatio_slide{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    border-radius: 15px;
    background-color: antiquewhite;
    width: 4vw;
    height: 5vw;
    margin-right: 5.2vw;
    text-align: center;
    font-size: 0.7vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.eachRatio_slide p{
    margin-top: 0.5vw;
    color: var(--color1);
    font-weight: bold;
}

.eachRatio_slide:last-child{
    margin-right: -5.2vw;
}

.eachRatio_slide_icon{
    width: 2vw;
    height: 2vw;
}

.eachRatio_slide_selected{
    width: 7.5vw;
    height: 8vw;
    font-size: 0.9vw;
}

.eachRatio_slide_selected div{
    width: 3.5vw;
    height: 3.5vw;
}

#profits{
    background-color: var(--color10);
}

#profits div{
    background-image: url(../../../../icons/profit.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#netProfit{
    background-color: var(--color5);
}

#netProfit div{
    background-image: url(../../../../icons/netProfit.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#freeCashFlow{
    background-color: var(--color12);
}

#freeCashFlow div{
    background-image: url(../../../../icons/freeCashFlow.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#ownersEquity{
    background-color: var(--color6);
}

#ownersEquity div{
    background-image: url(../../../../icons/ownersEquity.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#leftArrow{
    border: 0px solid black;
    width: 2vw;
    height: 2vw;

    background-image: url(../../../../icons/left_arrow_circle.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
     
}

#rightArrow{
    border: 0px solid black;
    width: 2vw;
    height: 2vw;

    background-image: url(../../../../icons/right_arrow_circle.png);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center center;
    background-size: auto;

    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}

@media screen and (max-width: 601px){
    #profit_scroll_overlay{
        height: 83.2vh;
        border-left: 0px solid var(--color7); 
        border-right: 0px solid var(--color7); 
    }

    /* FLAG............................................................................ */
    #ratio_Profit_flag{
        margin-top: 4.1vw;
        width: 85%;
        height: 28vw;
        background-color: var(--color10); 
        border-bottom-left-radius: 21px;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
        color: var(--color1);
        display: flex;
        overflow: hidden;
    }

    /* ratio_Profit_text............................................................................ */
    #ratio_Profit_text{
        margin-top: 8vh;
        width: 85%;
    }

    #ratio_Profit_text p{
        font-size: 1em;
    }

    /* ratio table.................................................................................. */
    #ratio_table_background{
        margin-top: 3.6vw;
        margin-bottom: 3.6vw;
        height: 110vw;
        width: 98%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #ratio_table{
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        width: 95%;
        max-height: 94%; 
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    #rato_table_header div{
        height: 10vw;
        font-size: 0.65em;
        word-wrap: break-word;
        word-break: break-word;
        background-color: var(--color1);
        padding-left: 0.08em;
        padding-right: 0.08em;
    }

    #rato_table_rows div{
        height: 8vw;
        font-size: 0.65em;
        padding-left: 0.08em;
        padding-right: 0.08em;
    }

    #ratioTable_sideBar_overlay{
        width: 1.2vw;
        height: 94%; 
        margin-left: 1vw;
    }

    #ratioTable_sideBar{
        width: 0vw;
        background-color: var(--color11);
        margin-top: 6vh;
    }

    /* set period.......................................................................................... */
    #profitSetPeriod_Overlay{
        width: 95vw;
        margin-bottom: 6vw; 
    }

    #profitSetPeriod_p{
        margin-bottom: 1.5vw;
        font-size: 0.7em;
    }

    #profitSetPeriod_p:after{
        width: calc(100% - 5em);
        left: 5em;
    }

    #profitSetPeriod_formOverlay{
        justify-content: space-between;
        height: 26vw;
    }

    #profitSetPeriodBTNS_Overlay div:first-child{
        width: 100%;
        margin-top: 0.75vw;
    }

    #profitSetPeriodBTNS_Overlay div:last-child{
        width: 100%;
        margin-bottom: 0.75vw;
    }

    #profitSetPeriodBTNS_Overlay button{
        font-size: 0.7em;
        width: 45%;
    }

    #profitSetPeriodInput_Overlay{
        width: 43%;
        border: 2px solid var(--color7);
        border-radius: 20px;
        margin-left: 0vw;
    }

    #profitSetPeriodInput_Overlay div{
        width: 83%;
        margin-right: 0%;
        font-size: 0.7em;
    }

    #profitSetPeriodInput_Overlay div input{
        width: 70%;
        margin: 0;
        margin-left: 0.5vw;
        padding-right: 0.75vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
        padding-left: 0.5vw;
        height: 8vw;
    }

    /* ...................................................................................................................................................... */
    #ratio_slide_BigOverlay{
        width: 95vw;
    }

    #ratio_slide_overlay{
        width: 75vw;
    }

    .eachRatio_slide{
        border-radius: 5px;
        width: 15vw;
        height: 14vw;
        margin-right: 5.2vw;
        font-size: 0.5em;
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .eachRatio_slide p{
        margin-top: 1vw;
    }

    .eachRatio_slide:last-child{
        margin-right: -5.2vw;
    }

    .eachRatio_slide_icon{
        width: 6vw;
        height: 6vw;
    }

    .eachRatio_slide_selected{
        width: 20vw;
        height: 20vw;
        font-size: 0.8em;
    }

    .eachRatio_slide_selected div{
        width: 8vw;
        height: 8vw;
    }

    #leftArrow{
        border: 0px solid black;
        width: 6vw;
        height: 6vw;
    }

    #rightArrow{
        border: 0px solid black;
        width: 6vw;
        height: 6vw;
    }
}