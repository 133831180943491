input{
    border-radius: 9px; 
    height: 2.3vw;
    border: 2.5px solid var(--color7);
    font-size: 0.8vw; 
    /* height: 2.1vw; */
}

button{
    background-color: var(--color1);
    border: 2.5px solid var(--color5);
    width: 6vw;
    height: 2.3vw;
    border-radius: 4px;
    font-size: 0.8vw; 
    color: var(--color5);
}

button:hover{
    background-color: var(--color5);
    border: 2.5px solid var(--color5);
}

select{
    height: 2.5vw;
}

#flilter_Overlay{
    /* height: 70vh; */
    height: calc(100vh - 12.35vw);
    background-color: var(--color1);
    border-left: 3px solid var(--color7); 
    border-right: 3px solid var(--color7); 
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
	align-items: center;
    width: 100%;
}

#ledger_filtter{
    width: 85%;
    margin-top: 3vw;
}


#filterCategoryOverlay{
    margin-top: 0vw;
    margin-bottom: 4vw;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#filterCategoryOverlay_p{
    margin-bottom: 0.7vw;
    width: 100%;
    position: relative;
    font-weight: bold;
}

#filterCategoryOverlay_p:after{
    content:" ";
    height: 1.5px;
    width: calc(93.4% - 4.5em);
    background: var(--color7);
    display: block;
    position: absolute;
    top: 68%;
    left: 4.5em;      /* change to em when the em font size for this p have been set */
}

#filterCategoryOverlay select{
    border: 2px solid var(--color7);
    background-color: white;
    border-radius: 9px;
    width: 26.4%;
    margin-top: 0.15vw;
    flex-wrap: wrap;
    padding-left: 0%;
}

#filterDate_overlay{
    margin-top: 2.5vw;
}

#setPeriod_p{
    position: relative;
}

#setPeriod_p:after{
    position: absolute;
    content:" ";
    height: 2px;
    width: calc(92.1% - 4.6em);
    background: var(--color7);
    display: block;
    position: absolute;
    top: 60%;
    left: 5em;
}

#dateDisp{
    color: var(--color8);
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
}

#filterDate div{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.25vw;
}

#filterDate div span{
    display: flex;
    flex-direction: row-reverse;
    width: 2.3vw;
    margin-right: 0.6vw;
}

#filterDate div input{
    color: var(--color8);
    width: 12.5vw;
    padding-left: 8px;
}

#filter_btn_Overlay{
    margin-top: 4vw;
    display: flex;
    flex-direction: row-reverse;
}

#filter_accountOverlay button{
    margin-top: 1vh;
    margin-top: 0.5vw;
    margin-right: 0.82vw;
    color: var(--textColor5);
}

#filter_accountOverlay button:hover{
    background-color: unset;
    color: var(--textColor5);
    border: 2px solid var(--color7);
}

#filter_btn{
    background-color: var(--color5);
    color: var(--color1);
    width: 12vw;
    height: 3vw;
    margin-right: 4vw;
}

#FilterError{
    color: red;
}

.streamBTNs{
    background-color: unset;
    border: 2.5px solid var(--color5);
}

@media screen and (max-width: 601px){
    #filter_section{
        height: 100%;
        height: 83.2vh;
    }

    input{
        border-radius: 9px; 
        height: 8.3vw;
        border: 2px solid var(--color7);
        /* font-size: 0.88em; */
        height: 11vw;
        background-color: white;
    }

    span{
        font-size: 0.88em;
    }
    
    button{
        background-color: var(--color1);
        border: 2px solid var(--color5);
        width: 20.8vw;
        border-radius: 4px;
        color: var(--color5);
        height: 10vw;
    }
    
    select{
        height: 10vw;
    }
    
    #flilter_Overlay{
        border-left: 0px solid var(--color7); 
        border-right: 0px solid var(--color7); 
    }
    
    #ledger_filtter{
        width: 89.5%;
        margin-top: 3vw;
    }
    
    #filterCategoryOverlay{
        margin-top: 3vw;
        margin-bottom: 4vw;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #filterCategoryOverlay_p{
        margin-bottom: 0.7vw;
        width: 100%;
        position: relative;
        font-weight: bold;
    }
    
    #filterCategoryOverlay_p:after{
        content:" ";
        height: 1.5px;
        width: calc(96% - 4.2em);
        background: var(--color7);
        display: block;
        position: absolute;
        top: 68%;
        left: 4.2em;      /* change to em when the em font size for this p have been set */
    }
    
    #filterCategoryOverlay select{
        border: 2px solid var(--color7);
        background-color: white;
        border-radius: 9px;
        width: calc(2.29em + 43%);
        margin-top: 0.15vw;
        flex-wrap: wrap;
        padding-left: 0%;
    }
    
    #setPeriod_p{
        position: relative;
    }
    
    #setPeriod_p:after{
        position: absolute;
        content:" ";
        height: 1.5px;
        width: calc(93% - 4.8em);
        background: var(--color7);
        display: block;
        position: absolute;
        top: 68%;
        left: 4.8em;
    }
    
    #dateDisp{
        color: rgb(113, 126, 139);
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
    }
    
    #filterDate_overlay{
        margin-top: 8vw;
        margin: 2px solid red;
    }
    
    #filterDate div{
        display: flex;
        flex-direction: row;    
        align-items: center;
        margin-bottom: 1.25vw;
    }
    
    #filterDate div span{
        display: flex;
        flex-direction: row-reverse;
        width: 2.29em;
        margin-right: 0.6vw;
    }
    
    #filterDate div input{
        color: rgb(173, 173, 173);
        width: 40%;
        padding-left: 8px;
        height: 9vw;
    }
    
    #filter_btn_Overlay{
        margin-top: 4vw;
        display: flex;
        flex-direction: row-reverse;
    
    }
    #filter_btn{
        background-color: var(--color5);
        color: var(--color1);
        width: 20vw;
        height: 10vw;
        margin-right: 4vw;
        font-size: 0.88em;
    }
    
    #FilterError{
        color: red;
    }
}